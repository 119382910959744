/**
 * @todo refactoring
 * @todo remove placeholder dates
 */

import * as moment from 'moment'
import 'moment/locale/fr'
import React from 'react'
import { Link } from 'gatsby'
import ProductAction from '../actions/ProductAction'
import Image from './Images'
import { AgeBadge } from './Badges'

import Style from '../styles/components/products.module.scss'

export default (props) => {
  moment.locale('fr');
  const variant = props.product.variants[0];
  const now = moment();
  const release_date = !props.product.release_date ? "2019-02-01T23:00:00+0000" : props.product.release_date;
  
  return (
    <div
      className={`${Style.item} ${
        Style.col6
      } col-6 _product-filtered ${props.product.filters.join(' ')} ${
        props.product.type
      }`}
    >
      <div
        className={
          now.isAfter(release_date)
            ? Style.product
            : `${Style.product} ${Style.productOpacity}`
        }
      >
        {props.product.age.text && (
          <AgeBadge text={props.product.age.text} class="products-list" />
        )}
        {now.isAfter(release_date) ? (
          <Link
            to={`${props.path}${props.product.projectId}/${props.product.uid}/`}
          >
            <Image src={props.product.image.url} alt={props.product.title} />
          </Link>
        ) : (
          <>
            <div className="pre-release">
              <p>{`À partir du ${moment(release_date).format('LL')}`}</p>
            </div>
            <Image src={props.product.image.url} alt={props.product.title} />
          </>
        )}
        <span>
          {props.context.langKey === 'fr'
            ? `${props.product.price ? props.product.price : variant.price}€`
            : `$${props.product.price ? props.product.price : variant.price}`}
        </span>
        <div className={Style.wrapper}>
          <h3>
            {now.isAfter(release_date) ? (
              <Link
                to={`${props.path}${props.product.projectId}/${props.product.uid}/`}
              >
                {props.product.title}
              </Link>
            ) : (
              <>{props.product.title}</>
            )}
          </h3>
          <p>
            {props.product.author}
            {props.product.illustrations && ` - ${props.product.illustrations}`}
          </p>
        </div>
        <ProductAction
          page={now.isAfter(release_date) ? `listing` : `listing-unset`}
          product={{ ...props.product }}
          disabled={props.context.langKey === 'en'}
          context={props.context}
          _t={props._t}
        />
      </div>
    </div>
  )
}
