/**
 * @todo refactoring
 */
import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from '../layouts/Index';
import Products from '../components/Products';
import StaticTextsPrepend from '../components/StaticTextsPrepend';
import SEO from '../components/SEO';

import Style from '../styles/pages/shop.module.scss'

const _PrismicToStaticContent = (props) => (
  <div className="page-head">
    <h2>{props['Boutique : Nos albums de coloriage animés'].text}</h2>
    <div dangerouslySetInnerHTML={{
      __html: props[`Avec l'application Wakatoon, ces livres de coloriages [...]`].html
    }}></div>
  </div>
);

class ShopifyStoreInterface extends React.Component {

  render() {
    
    const { data: { stories, books, pageContents, seo, staticText }, pageContext, location } = this.props;
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const PrismicToStaticContent = StaticTextsPrepend(_PrismicToStaticContent, pageContext);
    const _pageContentsData = pageContents.edges;
    const pageContentsData = _pageContentsData.length > 0 ? _pageContentsData[0].node.data : [];
    const _seoData = seo.edges;
    const seoData = _seoData.length > 0 ? _seoData[0].node.data : [];
    
    return (
      <Layout
        context={this.props.pageContext}
        pageTitle={seoData.shop_page_title.text}
        _t={staticPrismicTexts}
      >
        <SEO
          pageTitle={seoData.shop_page_title.text}
          pageDescription={seoData.shop_page_description.text}
          context={this.props.pageContext}
        />
        <React.Fragment>
          {this.props.viewportSize !== 'small-height' && (
            <div className="page">
              <section className="regular">
                <PrismicToStaticContent />
              </section>
            </div>
          )}
          <Products
            products={stories}
            books={books}
            path={this.props.pageContext.slug}
            shopUrl={this.props.pageContext.slug}
            filter={
              location.search.split('=')[0] === '?f'
                ? location.search.split('=')[1]
                : ''
            }
            location={location}
            context={this.props.pageContext}
            _t={staticPrismicTexts}
          />
          <div
            className={Style.footer}
            dangerouslySetInnerHTML={{
              __html: pageContentsData.footer_contents.html,
            }}
          ></div>
        </React.Fragment>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  viewportSize: state.viewport.viewportSize
});
export default connect(mapStateToProps)(ShopifyStoreInterface);

export const pageQuery = graphql`
  query ShopifyStoreInterface($locale: String!) {
    stories: allPrismicStory(
      sort: { fields: data___title___text, order: ASC },
      filter: { lang: {eq: $locale}}
    ){
      edges { ...stories }
    }
    books: allPrismicBlockStoryslider(filter: { lang: { eq: $locale} }) {
      edges { ...books }
    }
    pageContents: allPrismicPageShop(filter: { lang: { eq: $locale} }) {
      edges {
        node {
          lang
          data {
            footer_contents {
              html
            }
          }
        }
      }
    }
    seo: allPrismicStaticpagesseo(filter: { lang: { eq: $locale} }) {
      edges {
        ...StaticPagesSeo
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;
