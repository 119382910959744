import React from 'react'
import { connect } from 'react-redux'
import slugify from 'slugify'
import Product from './Product'
import Books from '../components/Banners/Books'
import Menui18n from '../../config/menui18n'
import DataFilters from '../components/DataFilters'

import Style from '../styles/components/products.module.scss'

class Products extends React.Component {
  render() {
    const {
      context: { langKey, locale },
      products,
      books,
      viewportSize,
      shopUrl,
      filter,
      location,
    } = this.props

    const data = products.edges
      .filter(edge => {
        return edge.node.lang === locale
      })
      .filter(
        product =>
          product.node.data.shopify_item !== null &&
          JSON.parse(product.node.data.visible)
      )

    const filters =
      Menui18n[langKey][Menui18n[langKey].findIndex(item => item.id === 'shop')]
        .filters

    const _products = data.map(product => {
      let item = product.node.data.shopify_item
      item.adminId = Buffer.from(item.admin_graphql_api_id.toString()).toString(
        'base64'
      )
      item.id = Buffer.from(
        item.variants[0].admin_graphql_api_id.toString()
      ).toString('base64')
      item.title = product.node.data.title.text
      item.price = product.node.data.price.text
      item.projectId = product.node.data.project_id
      item.name = product.node.data.title.text
      item.author = product.node.data.author.text
      item.illustrations = product.node.data.illustrations.text
      item.age = product.node.data.age
      item.image = product.node.data.image
      item.uid = product.node.uid
      item.type = product.node.data.type
      item.isPrintable = JSON.parse(product.node.data.printable)
      item.isBook = JSON.parse(product.node.data.physical_book)
      item.release_date = product.node.data.release_date
      item.inAppPurchase = JSON.parse(product.node.data.in_app_purchase)
      item.popupBackground = product.node.data.popup_background
      item.filters = product.node.data.age_filters.map(f => {
        return slugify(f.filter.document[0].data.label, {
          remove: /[*+~.()'"?!:@]/g,
          lower: true,
        })
      })
      return item
    })

    return viewportSize === 'small-height' ? (
      <Books {...books} shopUrl={shopUrl} />
    ) : (
      <div className="container products-container">
        <React.Fragment>
          <div style={{ marginTop: '-50px', paddingBottom: '50px' }}>
            <DataFilters
              filters={filters}
              element="_product-filtered"
              filter={filter}
              location={location}
              _t={this.props._t}
            />
          </div>
          <section className="regular" style={{ paddingBottom: 0 }}>
            <div className={`${Style.collection} row`}>
              {_products.map(product => {
                return (
                  product && (
                    <Product
                      key={product.id.toString()}
                      product={product}
                      {...this.props}
                    />
                  )
                )
              })}
            </div>
          </section>
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // cart: state.cart,
  viewportSize: state.viewport.viewportSize,
})

export default connect(mapStateToProps)(Products)
