import React from 'react';
import ClickOutHandler from 'react-onclickout';
import Style from '../styles/components/dataFilters.module.scss';

export default class extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      'active': false,
      'activeFilter': 'undefined'
    }
  }

  componentDidMount() {
    if (typeof this.props.filter !== 'undefined') {
      if (this.props.filter !== '') {
        this._filter(this.props.filter);
      }
    }
  }

  toggleFilters = () => {
    this.setState({ active: !this.state.active });
  }

  _filter = (e) => {
    var i, containers = document.getElementsByClassName(this.props.element);
    if (e === 'all') {
      this.setState({ 'activeFilter': 'undefined' });
      for (i = 0; i < containers.length; i++) {
        containers[i].style.display = "block";
      }
    } else {
      this.setState({ 'activeFilter': e })
      var x = document.getElementsByClassName(e);
      for (i = 0; i < containers.length; i++) {
        containers[i].style.display = "none";
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "block";
      }
    }
    this.setState({ 'active': false });
  }

  handleClickOut = (e) => {
    this.state.active && this.toggleFilters();
  }

  _t = (str) => this.props._t[this.props._t.findIndex(item => item.key_text === str)].text.text;

  render() {
    const Filters = () => (
      <>
        {Object.keys(this.props.filters).map((index) => {
          let item = this.props.filters[index];
          return (
            <div key={`filter-category-${index}`}>
              <h4>{item.category}</h4>
              <ul>
                {Object.keys(item.filters).sort().map((filterIndex) => {
                  let filter = item.filters[filterIndex];
                  return (
                    <li
                      onClick={() => this._filter(filter.anchor)}
                      key={`filter-${filterIndex}`}
                      className={`${Style.filtersItem} ${this.state.activeFilter === filter.anchor && Style.active}`}
                    >
                      {filter.label}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </>
    )
    
    return (
      <div className={Style.filtersContainer}>
        <ClickOutHandler onClickOut={this.handleClickOut}>
          <div className={`${Style.filters} ${this.state.active ? Style.active : ''}`}>
            <button 
              className="button btn btn_default" 
              onClick={this.toggleFilters}
            >
              {this._t('Filtrer')}
            </button>
            <div className={Style.filtersExpand}>
              <ul>
                <li data-href="/" onClick={() => this._filter('all')}>
                  {this._t('Tous')}
                </li>
              </ul>
              <Filters />
            </div>
          </div>
        </ClickOutHandler>
      </div>
    )
  }
}
